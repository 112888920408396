import { Container } from '@components/ui'
import cn from 'classnames'
import s from './FeatureBar.module.css'

interface FeatureBarProps {
  className?: string
  title: string
  hide?: boolean
  action?: React.ReactNode
}

const FeatureBar: React.FC<FeatureBarProps> = ({
  title,
  className,
  action,
  hide,
}) => {
  const rootClassName = cn(
    s.root,
    {
      transform: true,
      'translate-y-0 opacity-100': !hide,
      'translate-y-full opacity-0': hide,
    },
    className
  )
  return (
    <div className={rootClassName}>
      <Container className="text-center flex flex-col md:flex-row md:text-left gap-4 md:gap-8 items-center">
        <p>{title}</p>
        {action && (
          <div className="flex flex-col md:flex-row items-center justify-center gap-4">
            {action}
          </div>
        )}
      </Container>
    </div>
  )
}

export default FeatureBar
